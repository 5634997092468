(function($) {
  $.fn.ajaxForm = function(_options) {
    var options = _options || {};

    this.each(function() {
      var $form = $(this);

      $form.on("ajax:beforeSend", function(a, b, settings) {
        settings["dataType"] = "json";
        $form.inlineErrors("reset");
      }).on("ajax:success", function(a, b, c, xhr) {
        if(options.success) {
          options.success($form, xhr);
          return;
        }

        if($form.data("reload")) {
          location.reload();
          return;
        }
        var url = xhr.getResponseHeader("Location");
        if(url) location.href = url;
      }).on("ajax:error", function(a, xhr) {
        if(xhr.status == 401) {
          window.location = xhr.getResponseHeader("Location");
          return;
        } else if(!xhr.responseJSON) return;
        $.inlineErrorsUpdate($form, xhr.responseJSON["errors"]);
        $form.trigger("ajax:formErrors", [xhr.responseJSON]);
      });
    });
    return this;
  }
})(jQuery);
