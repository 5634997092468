const getPages = ($wizard, page) => $wizard.find(page ? `[data-wizard-page="${page}"]` : '[data-wizard-page]');

(($) => {
  $.fn.wizard = function() {
    this.each(function() {
      var $wizard = $(this),
        $back = $wizard.find('[data-back-button]'),
        $next = $wizard.find('[data-next-button]'),
        $form = $wizard.parents("form"),
        pages = [], currentPage, locked = false;
      getPages($wizard).each(function() { pages.push($(this).data("wizardPage")); });

      var updateWizard = function(newPage) {
        if(locked) return;
        currentPage = newPage;

        if((pages.indexOf(currentPage) == 0) || ((currentPage == pages.length) && $wizard.data("wizardReview"))) {
          $wizard.find('[data-cancel-button]').show();
          $back.hide();
        } else {
          $back.show();
          $wizard.find('[data-cancel-button]').hide();
        }

        if(pages.indexOf(currentPage) >= (pages.length - 1)) {
          $next.hide();
          $wizard.find('[data-finish-button]').show();
        } else {
          $wizard.find('[data-finish-button]').hide();
          $next.show();
        }
        getPages($wizard).hide();
        getPages($wizard, currentPage).show();
        $wizard.trigger("wizard:pageChanged", { page: currentPage });
        window.scrollToTop();
      }
      updateWizard(pages[0]);

      $form.on("ajax:formErrors", function() {
        var page = $form.inlineErrors("active").first().parents("[data-wizard-page]").data("wizardPage");
        updateWizard(page || pages[0]);
      }).on('ajax:beforeLoad', function() {
        $form.find('a[data-wizard-link]').prop('disabled', true);
      }).on('ajax:complete', function() {
        $form.find('a[data-wizard-link]').prop('disabled', false);
      });

      // prevent form submit on hitting "Enter" key
      $wizard.on("keypress", function(e) {
        if(e.keyCode == 13) {
          e.preventDefault();
          e.stopPropagation();
        }
      });

      // wizard navigation
      $next.on("click", function(e) {
        if(locked) return;

        locked = true;
        var $page = getPages($wizard, currentPage);
        $page.inlineErrors("reset");
        $.ajax($wizard.data('validate'), {
          cache: false,
          data: $form.serialize(),
          dataType: 'json',
          type: 'PATCH'
        }).always(() => locked = false)
          .success(function() { updateWizard(currentPage + 1); })
          .error(function(xhr) {
            window.app.get('ngAjax').onErrorResponse(xhr, function() {
              var turnPage = true;
              if($.inlineErrorsUpdate($page, xhr.responseJSON["errors"])) turnPage = false;
              if($.inlinePopupErrorsUpdate($page, xhr.responseJSON["errors"])) turnPage = false;
              if(turnPage) updateWizard(currentPage + 1);
            });
          });
      });

      $back.on("click", function(e) {
        updateWizard(currentPage - 1);
      });

      $wizard.on("click", "a[data-wizard-link]", function(e) {
        e.preventDefault();
        var page = $(this).data("wizardLink");
        updateWizard(page);
      });
    });
  }

  $(function() { $("[data-wizard]").wizard(); });
})(jQuery);
