(function($) {
  $.fn.inlineErrors = function(action, options) {
    if(!options) options = {};
    var selector = null;
    if(options.key)
      selector = "[data-error-for=" + options.key + "]";
    else
      selector = "[data-error-for]"

    var $dom = this.find(selector);

    switch(action) {
      case "reset":
        $dom.removeClass('d-block').empty();
        break;
      case "update":
        var content = options.content;
        if(content instanceof Array) content = content[0];
        $dom.addClass('d-block').html(content);
        break;
      case "active":
        $dom = $dom.filter(".d-block");
        break;

    }
    return $dom;
  }

  $.inlineErrorsUpdate = function($dom, list) {
    var applied = false
    $.each(list, function(key, content) {
      var $items = $dom.inlineErrors("update", { key: key, content: content });
      if($items.length != 0) applied = true;
    });
    return applied;
  };

  $.inlinePopupErrorsUpdate = function($dom, list) {
    var applied = false;
    $.each(list, function(key) {
      var $popup = $dom.find('[data-error-popup-for="' + key + '"]');
      if($popup.length != 0) {
        applied = true;
        $popup.modal("show");
        return false;
      }
    });
    return applied;
  }
})(jQuery);
