import './legacy/jquery'
import "jquery-ujs"
import "bootstrap-sass/assets/javascripts/bootstrap"
import "./legacy/ajax-form"
import "./legacy/inline-errors"
import "./legacy/wizard"
import { syncLocationPath } from '../components/tabs'

export default () => {
  jQuery(() => {
    $('body').on('ajax:success', 'a[data-remote="reload"]', () => window.location.reload());

    $('body').on('show.bs.tab', e => {
      let tab = e.target.getAttribute('href').slice(1);
      if (tab) {
        syncLocationPath(tab);
      }
    });

    $('body').on('shown.bs.dropdown', '.dropdown', e => {
      let dropdown = e.target;
      let menu = dropdown.querySelector('.dropdown-menu').getBoundingClientRect();
      if (window.innerHeight < (menu.top + menu.height)) {
        dropdown.classList.add('dropup');
      }
    }).on('hidden.bs.dropdown', '.dropdown', e => e.target.classList.remove('dropup'));
  });
}
